/*
 *   
 *  App Styles  
 *   
*/
.gotham-bold {
  font-family: 'Gotham Bold', Arial, Helvetica, sans-serif;
  font-weight: 700 !important;
}
.gotham-medium {
  font-family: 'Gotham Medium', Arial, Helvetica, sans-serif;
  font-weight: 500 !important;
}
.gotham-book {
  font-family: 'Gotham Book', Arial, Helvetica, sans-serif;
  font-weight: 350 !important;
}
@font-face {
  font-family: "Gotham Bold";
  src: url(/content/fonts/Gotham/Gotham-Bold.woff) format("woff"), url(/content/fonts/Gotham/Gotham-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Medium";
  src: url(/content/fonts/Gotham/Gotham-Medium.woff) format("woff"), url(/content/fonts/Gotham/Gotham-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Book";
  src: url(/content/fonts/Gotham/Gotham-Book.woff) format("woff"), url(/content/fonts/Gotham/Gotham-Book.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
select textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-size: 16px;
  font-family: 'Gotham Medium', Arial, Helvetica, sans-serif;
  font-weight: 500 !important;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
    font-family: 'Gotham Medium', Arial, Helvetica, sans-serif;
    font-weight: 500 !important;
  }
}
select:focus,
button:focus,
input:focus,
.btn-primary:focus,
.form-control:focus {
  outline: none !important;
  outline-offset: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.card,
.card-body,
.card-footer {
  outline: none !important;
  outline-offset: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
}
.main-shadow {
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}
.left-shadow {
  box-shadow: -6px -1px 12px -1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -6px -1px 12px -1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -6px -1px 12px -1px rgba(0, 0, 0, 0.25);
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
#root {
  min-height: 100%;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  width: 100%;
  background-color: #A0A8B3;
}
button,
input {
  font-family: 'Gotham Medium', Arial, Helvetica, sans-serif;
  font-weight: 500 !important;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
  color: #FFFFFF !important;
}
a:focus {
  border: none;
  outline: none;
}
.card {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}
.card .card-body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  margin: 0 auto;
  position: relative;
}
.card .card-body .alert {
  width: 80%;
  top: 25px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.card-forgot-pass-wrapper {
  position: relative;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}
.card-forgot-pass-wrapper .card-sidebar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}
.card-signup-wrapper {
  position: relative;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}
.card-signup-wrapper .card-sidebar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
}
.discount-wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.discount-wrapper.green {
  background-image: url('content/svg/bg-small-triangle-green.svg');
}
.discount-wrapper.blue {
  background-image: url('content/svg/bg-small-triangle-blue.svg');
}
.blue-button {
  font-family: 'Gotham Medium', Arial, Helvetica, sans-serif;
  font-weight: 500 !important;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  padding: 15px 20px;
  min-width: 145px;
  margin-top: 25px;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  background-color: #2998D3;
  border-radius: 30px !important;
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}
.green-button {
  font-family: 'Gotham Medium', Arial, Helvetica, sans-serif;
  font-weight: 500 !important;
  display: block;
  height: 50px;
  width: 100%;
  margin-top: 15px;
  padding: 15px 0px;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 30px !important;
  background-color: #9DCB4E;
  border: 1px solid #9DCB4E;
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}
