/*
 *   
 *  User Styles  
 *   
*/
.gotham-bold {
  font-family: 'Gotham Bold', Arial, Helvetica, sans-serif;
  font-weight: 700 !important;
}
.gotham-medium {
  font-family: 'Gotham Medium', Arial, Helvetica, sans-serif;
  font-weight: 500 !important;
}
.gotham-book {
  font-family: 'Gotham Book', Arial, Helvetica, sans-serif;
  font-weight: 350 !important;
}
@font-face {
  font-family: "Gotham Bold";
  src: url(/content/fonts/Gotham/Gotham-Bold.woff) format("woff"), url(/content/fonts/Gotham/Gotham-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Medium";
  src: url(/content/fonts/Gotham/Gotham-Medium.woff) format("woff"), url(/content/fonts/Gotham/Gotham-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Book";
  src: url(/content/fonts/Gotham/Gotham-Book.woff) format("woff"), url(/content/fonts/Gotham/Gotham-Book.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
.user-wrapper {
  background-color: #E5E5E5;
  height: auto;
  width: 100%;
  min-height: 100%;
}
.user-wrapper .loading-screen {
  height: 50vh;
}
